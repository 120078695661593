import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnInit,
    HostBinding,
    HostListener
} from "@angular/core"
import {
    PlanEstudioAplicado,
    PlanEstudioAplicadoInstancia,
    PlanEstudioAplicadoInstancias
} from "@puntaje/puntaje/api-services"
import { PlanEstudio, PlanEstudios, PlanEstudioSesion, Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { EstrellasRatingComponent } from "@puntaje/puntaje/new-modules/shared"
import { trigger, state, style, animate, transition, keyframes } from "@angular/animations"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Router } from "@angular/router"
import { BehaviorSubject, Subscription, combineLatest } from "rxjs"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"

@Component({
    selector: "plan-estudio-box",
    templateUrl: "plan_estudio_box.component.html",
    styleUrls: ["plan_estudio_box.component.scss"],

    animations: [
        trigger("dropIn", [
            state("in", style({ transform: "translateY(0)" })),
            transition("void => *", [
                animate(
                    "0.5s 0ms ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "translateY(-20px)", offset: 0 }),
                        style({ opacity: 1, transform: "translateY(5px)", offset: 0.2 }),
                        style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                    ])
                )
            ])
        ])
    ]
})
export class PlanEstudioBoxComponent implements OnInit {
    @Input() _planEstudioAplicado: PlanEstudioAplicado
    @Input() planEstudioAplicadoInstancia: PlanEstudioAplicadoInstancia
    @Output() disableHappened: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() hideProgress: boolean = true
    @ViewChild("starRating") starRating: EstrellasRatingComponent
    @HostBinding("class.plan-estudio-box") isBox: boolean = true
    @HostBinding("@dropIn") buttonState: string = "in"
    planEstudio: PlanEstudio
    progressBarValue: number = 0
    @Input() planes_route: string = "/plan_estudios"
    asignatura: any
    showSesiones: boolean = false
    isDone: boolean = false
    inscritos: string = "-"
    config: typeof config = config
    nombreSesiones: String = config.plataforma.nombreSesiones || "Clases"

    sub: Subscription
    planEstudioAplicadoSubject = new BehaviorSubject<PlanEstudioAplicado>(null)
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @Input()
    set planEstudioAplicado(planEstudioAplicado: any) {
        this._planEstudioAplicado = planEstudioAplicado
        this.planEstudioAplicadoSubject.next(planEstudioAplicado)
    }

    get planEstudioAplicado() {
        return this._planEstudioAplicado
    }

    constructor(
        private authService: AuthService,
        private peaiService: PlanEstudioAplicadoInstancias,
        private router: Router,
        private peService: PlanEstudios,
        private store: Store<State>
    ) {}

    ngOnInit() {
        this.sub = combineLatest(
            this.planEstudioAplicadoSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([planEstudioAplicado, asignaturasByEvaluacionTipo]) => {
            let lista_asignaturas = asignaturasByEvaluacionTipo[config.plataforma.evaluacionDefault]
            this.asignatura = lista_asignaturas.find(l => l.asignatura == this._planEstudioAplicado.asignatura_nombre)
            if (this.asignatura) this.asignatura.id = this._planEstudioAplicado.asignatura_id

            this.peService
                .find(this.planEstudioAplicado.plan_estudio_id, { with_sesiones: true })
                .then((planEstudio: PlanEstudio) => {
                    this.planEstudio = planEstudio
                    this.showSesiones = true
                    let total = this.planEstudio.plan_estudio_sesiones.reduce((a, b) => a + b.cantidad_contenidos, 0)
                    let avance = this.planEstudioAplicadoInstancia.avance || 0
                    this.setProgressBarValue(avance, total)
                    this.checkIfDone(avance, total)
                })
        })
    }

    @HostListener("@dropIn.done", ["$event"]) animationDropInDone(event) {
        this.progressBarValue = 0
        if (this.starRating) {
            this.starRating.startAnimation()
        }
    }

    setProgressBarValue(avance: number, total: number) {
        this.progressBarValue = (avance / total) * 100
    }

    navigateToPlan() {
        if (!this.planEstudioAplicadoInstancia) {
            let peaiN = new PlanEstudioAplicadoInstancia()
            peaiN.plan_estudio_aplicado_id = this.planEstudioAplicado.id
            peaiN.usuario_id = this.authService.getUserData().id
            peaiN.estado = 1
            peaiN.avance = 0
            this.peaiService.save(peaiN).then(response => {
                this.router.navigate([this.planes_route, this.planEstudioAplicado.id])
            })
        } else {
            this.router.navigate([this.planes_route, this.planEstudioAplicado.id])
        }
    }

    disablePlan() {
        if (this.planEstudioAplicadoInstancia) {
            this.peaiService
                .disable(this.planEstudioAplicadoInstancia.id)
                .then((peai: PlanEstudioAplicadoInstancia) => {
                    this.disableHappened.emit(true)
                })
        }
    }

    checkIfDone(avance: number, total: number) {
        this.isDone = avance === total
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
