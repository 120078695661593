import { Component, Input, ViewChild, ChangeDetectorRef } from "@angular/core"
import { Router } from "@angular/router"
import { PlanEstudio, PlanEstudios, Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo, selectAsignaturasList } from "@puntaje/puntaje/store"
import { filter, last, first } from "rxjs/operators"

@Component({
    selector: "planes-estudios-asignaturas",
    templateUrl: "planes_estudios_asignaturas.component.html",
    styleUrls: ["planes_estudios_asignaturas.component.scss"]
})
export class PlanesEstudiosAsignaturasComponent {
    lista_asignaturas: any = []
    showLists: boolean = false
    firstColumn: any = []
    secondColumn: any = []
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() planes_route: string
    @Input() previewMode: boolean = false

    constructor(protected store: Store<State>, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.load()
    }

    public async load() {
        this.loadingLayout.standby()
        this.lista_asignaturas = null
        this.cdr.detectChanges()
        const asignaturasList = await this.store
            .pipe(
                select(selectAsignaturasList),
                filter(x => !!x),
                first()
            )
            .toPromise()
        this.lista_asignaturas = asignaturasList
        this.setAsignaturas()
    }

    setAsignaturas() {
        this.firstColumn = this.lista_asignaturas.slice(0, Math.ceil(this.lista_asignaturas.length / 2))
        this.secondColumn = this.lista_asignaturas.slice(Math.ceil(this.lista_asignaturas.length / 2))
        this.loadingLayout.ready()
        this.showLists = true
    }

    hideAsignatura(asignatura) {
        this.lista_asignaturas = this.lista_asignaturas.filter(a => a.id != asignatura.id)

        this.setAsignaturas()
    }

    asignaturaId(index, asignatura) {
        return asignatura.id
    }
}
