<loading-layout #loadingLayout *ngIf="showLoader"></loading-layout>
<div [class.hideContent]="showLoader">
    <div *ngIf="evaluationState == evaluationStates.Start" [@fadeIn]="animState">
        <ng-container *ngTemplateOutlet="progressButtonsUp"></ng-container>
        <div *ngIf="graduate; else otherPlatforms">
            <h3 class="result-title">
                Tu último porcentaje de desempeño fue de {{ evaluacionPorcentaje }}% -
                {{ evaluacionNota | roundfloat: 2 }}
            </h3>
        </div>
        <ng-template #otherPlatforms>
            <h3 class="result-title">Tu último porcentaje de desempeño fue de {{ evaluacionPorcentaje }}%</h3>
        </ng-template>
        <ng-container [ngTemplateOutlet]="circleResults"></ng-container>
        <ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
    </div>
    <div *ngIf="evaluationState == evaluationStates.Evaluating" [@fadeIn]="animState">
        <evaluacion-tiempo [invisibleClock]="true"></evaluacion-tiempo>
        <preguntas-ensayo
            #preguntasEnsayo
            [evaluacionId]="evaluacionId"
            [displayBtnEntregarEnsayo]="false"
            [enableRedirectAfterDone]="false"
            [enableDudas]="enableDudas"
            [enableReporte]="enableReporte"
        ></preguntas-ensayo>
        <div class="clearfix btn-container">
            <cui-button class="btn_style pull-right" type="button" (click)="onSendEvaluation()">
                Entregar evaluación
            </cui-button>
        </div>
    </div>
    <div *ngIf="evaluationState == evaluationStates.Results" [@fadeIn]="animState">
        <ng-container *ngTemplateOutlet="progressButtonsUp"></ng-container>
        <div *ngIf="graduate; else otherPlatforms3">
            <h3 class="result-title">
                Tu porcentaje de desempeño fue de {{ evaluacionPorcentaje }}% - {{ evaluacionNota | roundfloat: 2 }}
            </h3>
        </div>
        <ng-template #otherPlatforms3>
            <h3 class="result-title">Tu porcentaje de desempeño fue de {{ evaluacionPorcentaje }}%</h3>
        </ng-template>

        <ng-container *ngTemplateOutlet="circleResults"></ng-container>
        <h3 class="solution-title">Soluciones</h3>
        <respuestas-ensayo
            *ngIf="evaluacionInstanciaId"
            [evaluacionInstanciaId]="evaluacionInstanciaId"
            (onEvaluacionInstanciaReady)="onEvaluationInstanciaReady($event)"
            [enableDudas]="enableDudas"
            [enableReporte]="enableReporte"
        ></respuestas-ensayo>
    </div>
</div>

<ng-template #circleResults>
    <!--Barra circular de resultados-->
    <div class="circulo-resultado">
        <div class="radial-progress" [attr.data-progress]="evaluacionPorcentajeAnimated">
            <div class="circle">
                <div class="mask full">
                    <div class="fill"></div>
                </div>
                <div class="mask half">
                    <div class="fill"></div>
                    <div class="fill fix"></div>
                </div>
                <div class="shadow"></div>
            </div>
            <div class="inset">
                <div class="percentage">
                    <div class="numbers">
                        <div *ngIf="graduate; else otherPlatforms2">
                            <span class="graduate">
                                {{ evaluacionPorcentaje }}% - {{ evaluacionNota | roundfloat }}
                            </span>
                        </div>
                        <ng-template #otherPlatforms2>
                            <span>{{ evaluacionPorcentaje }}%</span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #progressButtonsUp>
    <!--Botones de progreso-->
    <div class="clearfix progress-btns">
        <button type="button" class="btn btn-default btn-progress pull-left" (click)="onRepeatEvaluation()">
            Volver a realizar
            <fa name="refresh" class="icono-right"></fa>
        </button>
        <ng-content select="plan-estudio-evaluacion-progress-buttons-slot-up"></ng-content>
    </div>
</ng-template>
<ng-template #progressButtonsDown>
    <!--Botones de progreso-->
    <div class="clearfix progress-btns">
        <button type="button" class="btn btn-default btn-progress pull-left" (click)="onRepeatEvaluation()">
            Volver a realizar
            <fa name="refresh" class="icono-right"></fa>
        </button>
        <ng-content select="plan-estudio-evaluacion-progress-buttons-slot-down"></ng-content>
    </div>
</ng-template>
