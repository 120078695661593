<div class="mis-planes clearfix" #misPlanes (window:resize)="checkSize()">
    <loading-layout #loadingLayout>
        <div
            class="flex-container"
            *ngIf="!loadingLayout.loading"
            [class.col-3-ghost]="colNumber3"
            [class.col-4-ghost]="colNumber4"
        >
            <ng-container *ngFor="let plan of planesEstudioAplicados">
                <plan-estudio-box
                    *ngIf="instancias[plan.id]"
                    class="mis-planes-col"
                    [class.col-number-1]="colNumber1"
                    [class.col-number-2]="colNumber2"
                    [class.col-number-3]="colNumber3"
                    [planEstudioAplicado]="plan"
                    [planEstudioAplicadoInstancia]="instancias[plan.id]"
                    [hideProgress]="hideProgress"
                    (disableHappened)="handleDisabled($event)"
                    [planes_route]="planes_route"
                ></plan-estudio-box>
            </ng-container>
            <div
                class="mis-planes-col"
                [class.col-number-1]="colNumber1"
                [class.col-number-2]="colNumber2"
                [class.col-number-3]="colNumber3"
            >
                <button
                    type="button"
                    class="add-btn btn"
                    [@fadeIn]="addBtnState"
                    easyScrollTo="lista-planes-estudios"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeIn'"
                    [easyScrollToPadding]="-80"
                >
                    Agregar Plan de Estudio
                    <fa name="plus-circle" class="icono"></fa>
                </button>
            </div>
        </div>
    </loading-layout>
</div>
