<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <datos-generales-plan-estudio
            *ngIf="planEstudio"
            [planEstudio]="planEstudio"
            [nombrePlan]="planEstudioAplicado.plan_estudio_aplicado || planEstudioAplicado.plan_estudio_nombre"
            [nombreAsignatura]="planEstudioAplicado.asignatura_nombre"
            [planEstudioAplicadoInstancia]="(planEstudioAplicado?.plan_estudio_aplicado_instancias)[0]"
        ></datos-generales-plan-estudio>
        <div class="sesiones-container">
            <div class="line-container" [@slideIn]="planEstudioSesiones" (@slideIn.done)="animationSlideInDone()">
                <div class="line-segment-space clearfix" *ngFor="let sesion of planEstudioSesiones; let i = index">
                    <div class="line-bullet"></div>
                    <div class="line-segment"></div>
                    <plan-estudio-sesion-box
                        [planEstudioSesion]="sesion"
                        [planEstudioSesionAplicadaInstancia]="sesionInstancias[sesion.id]"
                        [planEstudioAplicado]="planEstudioAplicado"
                        [pointRight]="i % 2 == 0"
                        [sesiones_route]="sesiones_route"
                        class="sesion-box"
                        [class.slideRight]="i % 2 == 0"
                        [class.slideLeft]="i % 2 != 0"
                    ></plan-estudio-sesion-box>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
