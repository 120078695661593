<div class="plan-estudio-navbar" *ngIf="planEstudioAplicado">
    <div class="pe-row">
        <div class="pe-cell cell-inicio">
            <a class="menuBtn" [routerLink]="planes_route + '/' + planEstudioAplicado.id">
                <ogr-icon name="plan-estudio"></ogr-icon>
            </a>
        </div>
        <!--<div class="pe-cell cell-previous">
			<button *ngIf="enablePrevious" class="previousBtn btn btn-default" (click)="goToPrevious()"><fa class="icono" name="arrow-left"></fa>&nbsp;Anterior</button>
		</div>-->
        <div class="pe-cell cell-title">
            <h4 class="nombrePlan">
                <span class="asignatura-icono {{ asignatura?.clase }}" *ngIf="asignatura">
                    <ogr-icon name="{{ asignatura?.icono }}"></ogr-icon>
                </span>
                {{ nombrePlan }}
            </h4>
        </div>
        <div class="pe-cell cell-next">
            <button *ngIf="enableNext" class="nextBtn btn btn-default" (click)="goToNext()">
                <span>Siguiente&nbsp;</span>
                <fa class="icono" name="arrow-right"></fa>
            </button>
        </div>
    </div>
</div>
