<ng-container *ngIf="!saved">
    <form novalidate [formGroup]="form" name="contacto-preparate">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input
                    [form]="form"
                    [params]="params['nombre']"
                    [(value)]="usuarioRegistro['nombre']"
                    [key]="'nombre'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_paterno']"
                    [(value)]="usuarioRegistro['apellido_paterno']"
                    [key]="'apellido_paterno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['apellido_materno']"
                    [(value)]="usuarioRegistro['apellido_materno']"
                    [key]="'apellido_materno'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['tipo_id']"
                    [(value)]="usuarioRegistro['tipo_id']"
                    [key]="'tipo_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['rut']"
                    [(value)]="usuarioRegistro['rut']"
                    [key]="'rut'"
                    *ngIf="usuarioRegistro['tipo_id'] == 0"
                    (click)="disabledId(usuarioRegistro['tipo_id'])"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['pasaporte']"
                    [(value)]="usuarioRegistro['pasaporte']"
                    [key]="'pasaporte'"
                    *ngIf="usuarioRegistro['tipo_id'] == 1"
                    (click)="disabledId(usuarioRegistro['tipo_id'])"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuarioRegistro['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuarioRegistro['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuarioRegistro['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['telefono']"
                    [(value)]="usuarioRegistro['telefono']"
                    [key]="'telefono'"
                ></form-input>
            </div>
            <div class="col-md-6">
                <h3>Datos Académicos</h3>

                <label [attr.for]="'colegio'">{{ params["colegio"].label }}</label>
                <autocomplete
                    [placeholder]="'Escribe y selecciona entre las opciones'"
                    [formControlName]="'colegio'"
                    [options]="filteredEstablecimientos"
                    (filterChange)="getEstablecimientos($event)"
                    (controlValue)="updateColegio($event)"
                    showTextFun="toString"
                    class="autocomplete"
                ></autocomplete>

                <form-input
                    [form]="form"
                    [params]="params['nivel_id']"
                    [(value)]="usuarioRegistro['nivel_id']"
                    [key]="'nivel_id'"
                    (valueChange)="nivelChanged($event)"
                ></form-input>
                <form-input
                    *ngIf="showYearPsu"
                    [form]="form"
                    [params]="params['year_psu']"
                    [(value)]="usuarioRegistro['year_psu']"
                    [key]="'year_psu'"
                ></form-input>

                <label>A continuación selecciona tu interés académico:</label>
                <embed-preferencia-carrera-sedes-by-sede
                    #preferencias
                    [universidadesNombres]="universidades"
                    [form]="form"
                    (emitPreferencias)="updatePreferencias($event)"
                ></embed-preferencia-carrera-sedes-by-sede>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuarioRegistro['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="save()" type="button" class="btn btn-default btn-block submit-btn">Regístrate</button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>
