import { MaterialComponent, BibliotecaComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeBibliotecaComponent } from "./home-biblioteca/home-biblioteca.component"

/* import { MaterialComponent } from "./material.component"
import { MaterialesComponent } from "./materiales.component"
import { MaterialesSIMCEComponent } from "./materiales_simce.component"

import { MaterialesSaberComponent } from "./materiales_saber.component"
import { RecursosInteractivosComponent } from "./recursos_interactivos.component"
import { MaterialUDDComponent } from "./material_udd.component"
import { MaterialAppsComponent } from "./material_apps.component"
import { BibliotecaComponent } from "./biblioteca.component" */

export const materialesRoutes: Routes = [
    /* { path: "materiales", component: MaterialesComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "materiales/:id", component: MaterialComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "materiales_simce", component: MaterialesSIMCEComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "materiales_saber", component: MaterialesSaberComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "recursos_interactivos", component: RecursosInteractivosComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "material_udd", component: MaterialUDDComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "material_app", component: MaterialAppsComponent, canActivate: [TokenGuard, AuthGuard] },*/
    {
        path: "biblioteca",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: BibliotecaComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "bibliotecas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeBibliotecaComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "materiales/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: MaterialComponent, data: {enableDudas: false}, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const materialesRouting = RouterModule.forChild(materialesRoutes)
