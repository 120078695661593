import { CUILayoutsModule } from "@puntaje/shared/cui"
import { PuntajeMaterialesModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { HomeBibliotecaComponent } from "./home-biblioteca/home-biblioteca.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { materialesRouting } from "./alumnos_materiales.routing"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

@NgModule({
    declarations: [HomeBibliotecaComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlumnosLayoutsModule,
        MaterialesModule,
        materialesRouting,
        Angular2FontawesomeModule,
        SharedModule,
        PuntajeMaterialesModule,
        CUILayoutsModule
    ]
})
export class AlumnosMaterialesModule {}
