<!-- Titulo -->
<cui-section-headline *ngIf="!hideTitle">Mis Planes de Estudio</cui-section-headline>
<planes-estudios-alumno
    id="mis-planes-estudios"
    (instanciasChanged)="handleInstanciasChanged($event)"
    [planes_route]="planes_route"
></planes-estudios-alumno>
<cui-section-headline *ngIf="!hideTitle">Planes de Estudio</cui-section-headline>
<planes-estudios-asignaturas
    #planesEstudioAsignaturas
    id="lista-planes-estudios"
    [planes_route]="planes_route"
></planes-estudios-asignaturas>
