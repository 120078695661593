import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "planes_estudios.component.html"
})
export class PlanesEstudiosComponent implements OnInit, OnDestroy {
    planesEstudioRoute: string
    pagina: string = "plan estudio"
    showArteHome: boolean = false

    subData: Subscription

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_estudios.list")
        this.subData = this.route.data.subscribe(data => {
            this.pagina = data.pagina ?? this.pagina
            this.planesEstudioRoute = data.planesEstudioRoute ?? this.planesEstudioRoute
            this.showArteHome = data.showArteHome ?? this.showArteHome
        })
    }

    ngOnDestroy(): void {
        this.subData.unsubscribe()
    }
}
