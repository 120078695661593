<div *ngIf="planesEstudioAplicados && planesEstudioAplicados.length > 0 && asignatura" class="categoryBox">
    <div class="title-container {{ asignatura.clase }}">
        <div class="title-table">
            <div class="title-row">
                <div class="title-cell icono-cell">
                    <div class="icono-container {{ asignatura.clase }}">
                        <ogr-icon name="{{ asignatura.icono }}" class="{{ asignatura.clase }}"></ogr-icon>
                    </div>
                </div>
                <div class="title-cell">
                    <h4 class="title-asignatura {{ asignatura.clase }}">{{ asignatura.asignatura }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div id="planes-estudio-{{ asignatura.clase }}" [expandableContent]>
        <div *ngFor="let plan of planesEstudioAplicados" class="plan-estudio-container">
            <div class="plan-estudio-table">
                <div class="plan-estudio-row">
                    <div class="plan-estudio-cell plan-estudio-icono-container">
                        <div class="plan-estudio-icono {{ asignatura.clase }}">
                            <ogr-icon class="icono {{ asignatura?.clase }}" name="lenguaje-3"></ogr-icon>
                        </div>
                    </div>
                    <div class="plan-estudio-cell">
                        <h3 class="title {{ asignatura.clase }}">
                            {{ plan.plan_estudio_aplicado || plan.plan_estudio_nombre }}
                        </h3>
                        <p class="inscritos" *ngIf="plan.inscritos != undefined && plan.inscritos != null">
                            <fa name="user-o" class="icono"></fa>
                            inscritos ({{ plan.inscritos }})
                        </p>
                    </div>
                    <div class="plan-estudio-cell">
                        <ng-container *ngIf="!previewMode">
                            <div *ngIf="instancias[plan.id]" class="inscrito">
                                Inscrito
                                <ogr-icon name="ticket-circle" class="ticket"></ogr-icon>
                            </div>
                            <button
                                type="button"
                                *ngIf="!instancias[plan.id]"
                                class="no-inscrito"
                                (click)="navigateToPlan(plan.id)"
                            >
                                Inscribir
                                <fa name="plus-circle" class="plus"></fa>
                            </button>
                        </ng-container>
                        <button
                            type="button"
                            *ngIf="previewMode"
                            class="preview"
                            (click)="navigateToPreviewPlan(plan.id)"
                        >
                            Ver plan
                            <fa name="arrow-right" class="flecha"></fa>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button type="button" toggleExpandable="planes-estudio-{{ asignatura.clase }}" class="expand-btn">
        <fa name="angle-down" class="arrow"></fa>
    </button>
</div>
