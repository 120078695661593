import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { RegisterUssComponent } from "@puntaje/puntaje/new-modules/usuarios"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    contactPhone: string = ""
    contactMail: string = ""
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("registroUss", { static: false }) registroUss: RegisterUssComponent
    showRegisterForm: boolean = true

    config: typeof config

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {
        this.contactPhone = config.plataforma.info.contacto.telefono ? config.plataforma.info.contacto.telefono : ""
        this.contactMail = config.plataforma.info.contacto.mail ? config.plataforma.info.contacto.mail : ""
    }

    ngOnInit() {
        this.config = config

        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
    }

    showRegister() {
        //this.showRegisterForm = true;
        this.openModal.emit()
    }

    cancelar() {
        //this.showRegisterForm = false;
        this.registroUss.clear()
    }
}
