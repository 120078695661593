import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan_estudio_sesiones.component.html"
})
export class PlanEstudioSesionesComponent implements OnInit, OnDestroy {
    sub: Subscription
    subData: Subscription
    sesiones_route: string
    planEstudioId: number
    planesEstudioRoute: string = "/plan_estudios"

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_estudios.plan_estudio_sesiones")
        this.subData = this.route.data.subscribe(data => {
            this.planesEstudioRoute = data.planesEstudioRoute ?? this.planesEstudioRoute
        })

        this.sub = this.route.params.subscribe(params => {
            this.planEstudioId = +params["id"]
            this.sesiones_route = this.planesEstudioRoute + "/" + this.planEstudioId + "/sesiones"
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }
}
