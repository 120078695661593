<cui-info-card>
    <cui-info-card-title>{{ nombrePlan }}</cui-info-card-title>
    <img
        cui-info-card-icon
        src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/library/images/apss_udds/plan_estudios_imagen.png"
        alt="Plan de estudio"
    />
    <cui-info-card-left-content>
        <cui-info-card-data-pair *ngIf="nombreAsignatura">
            <label cui-info-card-data-pair-label>Asignatura</label>
            <p cui-info-card-data-pair-value>{{ nombreAsignatura }}</p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair *ngIf="numeroSesiones">
            <label cui-info-card-data-pair-label>Número de Sesiones</label>
            <p cui-info-card-data-pair-value>{{ numeroSesiones }}</p>
        </cui-info-card-data-pair>
        <cui-info-card-data-pair>
            <label cui-info-card-data-pair-label>Puntuación</label>
            <p cui-info-card-data-pair-value>
                <estrellas-rating
                    #starRating
                    class="estrellas"
                    [starNumber]="planEstudioAplicadoInstancia.estrellas"
                    [autoStart]="true"
                ></estrellas-rating>
            </p>
        </cui-info-card-data-pair>
    </cui-info-card-left-content>
</cui-info-card>
