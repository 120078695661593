import { Component, ViewChild, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { PlanEstudioContenidoInstancia } from "@puntaje/puntaje/api-services"
import { PlanEstudioComponente, PlanEstudioLote, PlanEstudioMaterial } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "plan-estudio-sesion-menu",
    templateUrl: "plan_estudio_sesion_menu.component.html",
    styleUrls: ["plan_estudio_sesion_menu.component.scss"]
})
export class PlanEstudioSesionMenuComponent implements OnInit {
    @Input() planEstudioComponentes: PlanEstudioComponente[]
    @Input() currentComponentIndex: number = 0
    @Output() onComponentChange: EventEmitter<any> = new EventEmitter<any>()
    @Input() contenidoInstancias: { [x: string]: PlanEstudioContenidoInstancia } = {}
    mType = PlanEstudioMaterial
    lType = PlanEstudioLote
    @Input() previewMode: boolean = false

    constructor() {}

    ngOnInit() {}

    changeComponent(i: number) {
        this.currentComponentIndex = i
        this.onComponentChange.emit(this.currentComponentIndex)
    }

    isVisited(component: PlanEstudioComponente) {
        if (this.contenidoInstancias[component.id + component.tipo] || this.previewMode) {
            return true
        }
        return false
    }

    getTypeIcon(obj: any) {
        let thumbType = "libro"
        if (obj.constructor == this.mType) {
            switch ((obj as PlanEstudioMaterial).material.material_tipo_nombre) {
                case "Video":
                    thumbType = "video"
                    break
                case "Streaming":
                    thumbType = "video"
                    break
                case "Archivo":
                    thumbType = "guia-2"
                    break
                default:
                    thumbType = "guia-2"
                    break
            }
        } else if (obj.constructor == this.lType) {
            thumbType = "hacer-prueba"
        }
        return thumbType
    }

    checkLine(index: number) {
        if (index >= this.planEstudioComponentes.length - 1) return false
        if (
            this.isVisited(this.planEstudioComponentes[index]) &&
            this.isVisited(this.planEstudioComponentes[index + 1])
        ) {
            return true
        }
        return false
    }
}
