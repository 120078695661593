<footer title="Informacion de la empresa">
    <div class="txt-company-footer">
        <div class="social">
            <a href="{{ urlFacebook }}"><img class="img-footer" src="/assets/img/landing/facebook.png" /></a>
            <a href="{{ urlTwitter }}"><img class="img-footer" src="/assets/img/landing/twitter.png" /></a>
            <a href="{{ urlYoutube }}"><img class="img-footer" src="/assets/img/landing/youTube.png" /></a>
            <a href="{{ urlInstagram }}"><img class="img-footer" src="/assets/img/landing/instagram.png" /></a>
        </div>
        <hr />
        <img
            class="footer-logo"
            src="/assets/img/sello-footer.png"
            alt="{{ this.config.plataforma.info.companyName }}"
        />
    </div>
    <div class="contact-container">
        <img class="mail-img" src="/assets/img/landing/mailing.png" alt="Escríbenos" />
        <p class="contact-title">
            No dude en escribirnos a:
            <br />
            <em>{{ supportMail }}</em>
        </p>
    </div>
</footer>
