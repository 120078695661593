import { Component, Input, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    PlanEstudioComponente,
    PlanEstudioLote,
    PlanEstudioMaterial,
    PlanEstudioSesion,
    PlanEstudioSesiones
} from "@puntaje/nebulosa/api-services"
import { AuthService, ScrollToService } from "@puntaje/shared/core"
import {
    EvaluacionUsuario,
    EvaluacionUsuarios,
    PlanEstudioAplicado,
    PlanEstudioAplicadoInstancias,
    PlanEstudioAplicados,
    PlanEstudioContenidoInstancia,
    PlanEstudioContenidoInstancias,
    PlanEstudioSesionAplicadaInstancia,
    PlanEstudioSesionAplicadaInstancias
} from "@puntaje/puntaje/api-services"

@Component({
    selector: "plan-estudio-sesion",
    templateUrl: "plan_estudio_aplicado_sesion.component.html",
    styleUrls: ["plan_estudio_aplicado_sesion.component.scss"]
})
export class PlanEstudioAplicadoSesionComponent {
    sub: any
    @Input() plan_estudio_aplicado_id: number
    private _plan_estudio_sesion_id: number
    @Input() sesiones_route: string
    @Input() planes_route: string = "/plan_estudios"
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    plan_estudio_aplicado: PlanEstudioAplicado
    plan_estudio_sesion: PlanEstudioSesion
    plan_estudio_componentes: PlanEstudioComponente[]
    evaluaciones_usuario: EvaluacionUsuario[]
    hash_evaluaciones: any
    mType = PlanEstudioMaterial
    lType = PlanEstudioLote
    activeTab: number = 0
    //key de contenidoInstancias ahora es idContenido_TipoContenido
    contenidoInstancias: { [x: string]: PlanEstudioContenidoInstancia } = {}
    activeComponent: number = 0
    enableProgressBtn: boolean = false
    enablePreviousBtn: boolean = false
    nextSession: PlanEstudioSesion

    constructor(
        private peaService: PlanEstudioAplicados,
        private pesService: PlanEstudioSesiones,
        private evaluacionUsuariosService: EvaluacionUsuarios,
        private peciService: PlanEstudioContenidoInstancias,
        private pesaiService: PlanEstudioSesionAplicadaInstancias,
        protected peaiService: PlanEstudioAplicadoInstancias,
        private authService: AuthService,
        protected scrollToService: ScrollToService,
        protected router: Router
    ) {}

    @Input()
    set plan_estudio_sesion_id(plan_estudio_sesion_id: any) {
        this._plan_estudio_sesion_id = plan_estudio_sesion_id
        if (this._plan_estudio_sesion_id) this.setup()
    }
    get plan_estudio_sesion_id() {
        return this._plan_estudio_sesion_id
    }

    setup() {
        this.activeTab = 0
        this.activeComponent = 0
        this.enableProgressBtn = false
        this.enablePreviousBtn = false
        this.contenidoInstancias = {}
        this.plan_estudio_componentes = null
        this.nextSession = null
        this.peaService
            .find(this.plan_estudio_aplicado_id, {
                plan_estudio_aplicado_instancia: { usuario_id: this.authService.getUserData().id },
                plan_estudio_sesion_aplicada_instancia: { plan_estudio_sesion_id: this.plan_estudio_sesion_id }
            })
            .then((pea: PlanEstudioAplicado) => {
                pea.plan_estudio_aplicado_instancias.forEach((i, index) => {
                    i.plan_estudio_sesion_aplicada_instancias.forEach((si, index2) => {
                        si.plan_estudio_contenido_instancias.forEach((c, index3) => {
                            this.contenidoInstancias[c.plan_estudio_contenido_id + c.tipo] = c
                        })
                    })
                })
                this.plan_estudio_aplicado = pea
                this.hash_evaluaciones = {}
                this.plan_estudio_aplicado.plan_estudio_evaluaciones.forEach((pee, index) => {
                    if (pee.plan_estudio_sesion_id == this.plan_estudio_sesion_id) {
                        if (!this.hash_evaluaciones[pee.plan_estudio_sesion_id]) {
                            this.hash_evaluaciones[pee.plan_estudio_sesion_id] = {}
                        }
                        this.hash_evaluaciones[pee.plan_estudio_sesion_id][pee.orden] = pee.evaluacion_id
                    }
                })
                var promesas = []
                // guardar para el usuario actual una evaluacion_usuario por cada plan estudio evaluacion
                for (var key in this.hash_evaluaciones[this.plan_estudio_sesion_id]) {
                    var eu = new EvaluacionUsuario()
                    eu.usuario_id = this.authService.getUserData().id
                    eu.evaluacion_id = this.hash_evaluaciones[this.plan_estudio_sesion_id][key]
                    eu.receptor_type = "Usuario"
                    eu.receptor_id = this.authService.getUserData().id
                    promesas.push(this.evaluacionUsuariosService.save(eu))
                }

                return Promise.all(promesas).then((evaluacionUsuarios: EvaluacionUsuario[]) => {
                    this.evaluaciones_usuario = []
                    for (let r of evaluacionUsuarios) {
                        let ob = r
                        let eu = new EvaluacionUsuario()
                        eu.id = ob.id
                        eu.evaluacion_id = ob.evaluacion_id
                        eu.receptor_id = ob.receptor_id
                        eu.receptor_type = ob.receptor_type
                        eu.usuario_id = ob.usuario_id
                        this.evaluaciones_usuario.push(eu)
                    }
                    this.pesService
                        .find(this.plan_estudio_sesion_id, { with_componentes: true })
                        .then((pes: PlanEstudioSesion) => {
                            this.plan_estudio_sesion = pes

                            this.plan_estudio_componentes = this.ordenar(
                                this.plan_estudio_sesion.plan_estudio_materiales,
                                this.plan_estudio_sesion.plan_estudio_lotes
                            )
                            this.markAsSeen(this.plan_estudio_componentes[0])
                            this.loadingLayout.ready()
                            this.pesService
                                .where({
                                    plan_estudio_sesion: {
                                        plan_estudio_id: this.plan_estudio_sesion.plan_estudio_id,
                                        orden: this.plan_estudio_sesion.orden + 1
                                    }
                                })
                                .then((nextPes: PlanEstudioSesion[]) => {
                                    if (nextPes.length > 0) {
                                        this.nextSession = nextPes[0]
                                    }
                                    this.enableProgressBtn = true
                                    this.enablePreviousBtn = true
                                })
                        })
                })
            })
    }

    markAsSeen(c: PlanEstudioComponente) {
        if (c) {
            let ci = this.contenidoInstancias[c.id + c.tipo]
            if (!ci) {
                var peciN = new PlanEstudioContenidoInstancia()
                peciN.plan_estudio_contenido_id = c.id
                peciN.usuario_id = this.authService.getUserData().id
                peciN.estado = 1
                if (this.type(c) === this.mType) {
                    peciN.avance = 1
                    peciN.tipo = "Material"
                }
                if (this.type(c) === this.lType) {
                    peciN.avance = 0
                    peciN.tipo = "Lote"
                }

                this.plan_estudio_aplicado.plan_estudio_aplicado_instancias.forEach((i, index) => {
                    if (i.plan_estudio_sesion_aplicada_instancias.length > 0) {
                        peciN.plan_estudio_sesion_aplicada_instancia_id =
                            i.plan_estudio_sesion_aplicada_instancias[0].id
                        this.peciService.save(peciN).then((i: PlanEstudioContenidoInstancia) => {
                            if (i) this.contenidoInstancias[i.plan_estudio_contenido_id + i.tipo] = i
                        })
                    }
                })
            }
        }
    }

    onMenuChangeComponent(component_id) {
        this.activeComponent = component_id
    }

    goToNextComponent() {
        this.activeComponent++
        if (this.plan_estudio_componentes[this.activeComponent]) {
            this.markAsSeen(this.plan_estudio_componentes[this.activeComponent])
        }
        this.scrollToService.scrollToTop()
    }

    goToNextSesion() {
        let peai_id = this.plan_estudio_aplicado.plan_estudio_aplicado_instancias[0].id
        this.pesaiService
            .where({
                plan_estudio_sesion_aplicada_instancia: {
                    plan_estudio_aplicado_instancia_id: peai_id,
                    plan_estudio_sesion_id: this.nextSession.id,
                    usuario_id: this.authService.getUserData().id
                }
            })
            .then((saInstancias: PlanEstudioSesionAplicadaInstancia[]) => {
                if (saInstancias.length > 0) {
                    this.router.navigate([this.sesiones_route, this.nextSession.id])
                } else {
                    this.navigateToSesion(this.nextSession.id)
                }
            })
    }

    goToEnd() {
        //TODO: cambiar esto por un final más interesante
        this.router.navigate(["/planes_estudio"])
    }

    goToNext() {
        if (this.activeComponent < this.plan_estudio_componentes.length - 1) {
            this.goToNextComponent()
        } else if (this.nextSession) {
            this.goToNextSesion()
        } else {
            this.goToEnd()
        }
    }

    navigateToSesion(sesionId) {
        var pesaiN = new PlanEstudioSesionAplicadaInstancia()
        pesaiN.plan_estudio_sesion_id = sesionId
        pesaiN.usuario_id = this.authService.getUserData().id
        pesaiN.estado = 1
        pesaiN.avance = 0
        pesaiN.plan_estudio_aplicado_instancia_id = this.plan_estudio_aplicado.plan_estudio_aplicado_instancias[0].id
        this.pesaiService.save(pesaiN).then(response => {
            this.router.navigate([this.sesiones_route, sesionId])
        })
    }

    checkIfEvaluationExists(orden: number) {
        return (
            this.hash_evaluaciones &&
            this.hash_evaluaciones[this.plan_estudio_sesion.id] &&
            this.hash_evaluaciones[this.plan_estudio_sesion.id][orden]
        )
    }

    private ordenar(list_m: PlanEstudioComponente[], list_l: PlanEstudioComponente[]) {
        return list_m.concat(list_l).sort((a, b) => {
            return a.orden - b.orden
        })
    }

    type(obj: any) {
        return obj.constructor
    }

    prettyType(obj: any) {
        let type = this.type(obj)
        if (type === this.mType) {
            return "Material"
        } else if (type === this.lType) {
            return "Lote"
        }
        return type
    }

    isActive(i): boolean {
        return i == this.activeTab ? true : false
    }

    setActive(i): void {
        this.activeTab = i
    }
}
