import { PuntajeEstadisticasModule } from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

import { estadisticasRouting } from "./alumnos_estadisticas.routing"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlumnosLayoutsModule,
        EstadisticasModule,
        estadisticasRouting,
        EnsayosModule,
        SharedModule,
        PuntajeEstadisticasModule
    ],
    declarations: [],
    exports: []
})
export class AlumnosEstadisticasModule {}
