<loading-layout #loadingLayout>
    <div *ngIf="!loadingLayout.loading">
        <div class="planes-container">
            <div *ngIf="showLists" class="row">
                <div class="col-md-6">
                    <planes-estudios-asignatura
                        [asignatura]="a"
                        [planes_route]="planes_route"
                        [previewMode]="previewMode"
                        *ngFor="let a of firstColumn; trackBy: asignaturaId"
                        (noPlanes)="hideAsignatura($event)"
                    ></planes-estudios-asignatura>
                </div>
                <div class="col-md-6">
                    <planes-estudios-asignatura
                        [asignatura]="a"
                        [planes_route]="planes_route"
                        [previewMode]="previewMode"
                        *ngFor="let a of secondColumn; trackBy: asignaturaId"
                        (noPlanes)="hideAsignatura($event)"
                    ></planes-estudios-asignatura>
                </div>
            </div>
        </div>
    </div>
</loading-layout>
