<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>Bienvenid@</ll-titulo>

<div class="row">
    <div class="col-md-8 col-sm-6">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <usuario-showcasebox></usuario-showcasebox>
                <br />
                <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
                <!-- <conversor-showcasebox></conversor-showcasebox> -->
            </div>
            <div class="col-md-6 col-sm-12">
                <planes-estudios-showcasebox
                    [asignaturasBoxInsteadPlanClase]="true"
                    [enablePlanesPersonales]="true"
                    [maxColumns]="1"
                ></planes-estudios-showcasebox>
                <br />
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>

        <br />
    </div>
</div>
