<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>Planes de Estudio</ll-titulo>

<div class="row">
    <div [class.col-md-8]="showArteHome" [class.col-sm-6]="showArteHome">
        <planes-estudio *ngIf="planesEstudioRoute" [planes_route]="planesEstudioRoute"></planes-estudio>
    </div>
    <div *ngIf="showArteHome" class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
    </div>
</div>
