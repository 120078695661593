import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    RealizarEnsayoComponent,
    EnsayoInstanciasComponent,
    EvaluacionesCompartidasComponent,
    GenerarEnsayoComponent,
    ResultadosEnsayoComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { AuthGuard } from "@puntaje/shared/core"

import { ProgresoAlumnoComponent, EstadisticasAlumnoComponent } from "@puntaje/puntaje/core"

import { HomePruebaTransicionComponent } from "./home-prueba-transicion/home-prueba-transicion.component"

export const routes: Routes = [
    {
        path: "pruebas_de_transicion/ensayos/generar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoComponent,
                canActivate: [AuthGuard],
                data: { evaluacionTipos: ["prueba de transición"] }
            }
        ]
    },
    {
        path: "ensayos_paes",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoComponent,
                canActivate: [AuthGuard],
                data: { evaluacionTipos: ["paes"] }
            }
        ]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/generar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/historial",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/compartidos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent, canActivate: [AuthGuard] }]
    },
    { path: "ensayos/progreso", component: ProgresoAlumnoComponent, canActivate: [AuthGuard] },
    { path: "ensayos/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [AuthGuard] },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEnsayoComponent,
                canActivate: [AuthGuard],
                data: {
                    rutaVerEvaluaciones: "/ensayos/historial",
                    enableDudas: false
                }
            }
        ]
    },
    /**Pruebas de Transicion */
    {
        path: "pruebas_de_transicion",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebaTransicionComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas_de_transicion/historial",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard],
                data: { evaluacionTipos: ["prueba de transición"] }
            }
        ]
    },
    /** PAES **/
    {
        path: "paes",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebaTransicionComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
