import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import {
    PlanEstudioAplicados,
    PlanEstudioAplicado,
    PlanEstudioSesionAplicadaInstancia,
    PlanEstudioSesionAplicadaInstancias,
    PlanEstudioAplicadoInstancia,
    PlanEstudioAplicadoInstancias
} from "@puntaje/puntaje/api-services"
import { PlanEstudio, PlanEstudios, PlanEstudioSesion } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Location } from "@angular/common"
import { PlanEstudioSesionBoxComponent } from "./plan_estudio_sesion_box.component"

@Component({
    selector: "plan-estudio-sesiones",
    templateUrl: "plan_estudio_sesiones.component.html",
    styleUrls: ["plan_estudio_sesiones.component.scss"],
    animations: [
        trigger("slideIn", [
            transition("* => *", [
                query(".slideRight", style({ opacity: 0 }), { optional: true }),
                query(".slideLeft", style({ opacity: 0 }), { optional: true }),
                query(
                    ".slideRight",
                    [
                        stagger(200, [
                            animate(
                                "0.3s ease-in",
                                keyframes([
                                    style({ opacity: 0, transform: "translateX(-30px)", offset: 0 }),
                                    style({ opacity: 1, transform: "translateX(5px)", offset: 0.8 }),
                                    style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                                ])
                            )
                        ])
                    ],
                    { optional: true }
                ),
                query(
                    ".slideLeft",
                    [
                        stagger(200, [
                            animate(
                                "0.3s 0.1s ease-in",
                                keyframes([
                                    style({ opacity: 0, transform: "translateX(30px)", offset: 0 }),
                                    style({ opacity: 1, transform: "translateX(-5px)", offset: 0.8 }),
                                    style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
                                ])
                            )
                        ])
                    ],
                    { optional: true }
                )
            ])
        ])
    ]
})
export class PlanEstudioSesionesComponent implements OnInit {
    planEstudio: PlanEstudio
    planEstudioAplicado: PlanEstudioAplicado
    planEstudioSesiones: PlanEstudioSesion[]
    sesionInstancias: { [x: number]: PlanEstudioSesionAplicadaInstancia } = {}
    @Input() planEstudioId: number
    @Input() sesiones_route: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        private router: Router,
        private peService: PlanEstudios,
        private authService: AuthService,
        private peaService: PlanEstudioAplicados,
        private peaiService: PlanEstudioAplicadoInstancias,
        private pesaService: PlanEstudioSesionAplicadaInstancias,
        protected location: Location
    ) {}

    ngOnInit() {
        this.peaService
            .find(this.planEstudioId, {
                plan_estudio_aplicado_instancia: { usuario_id: this.authService.getUserData().id }
            })
            .then((planEstudioAplicado: PlanEstudioAplicado) => {
                this.planEstudioAplicado = planEstudioAplicado
                this.peService
                    .find(this.planEstudioAplicado.plan_estudio_id, { with_sesiones: true })
                    .then((planEstudio: PlanEstudio) => {
                        this.planEstudio = planEstudio
                        this.planEstudioAplicado.plan_estudio = this.planEstudio
                        this.planEstudioSesiones = this.planEstudio.plan_estudio_sesiones
                        this.planEstudioAplicado.plan_estudio_aplicado_instancias.forEach((i, index) => {
                            i.plan_estudio_sesion_aplicada_instancias.forEach((j, index2) => {
                                this.sesionInstancias[j.plan_estudio_sesion_id] = j
                            })
                        })
                        this.loadingLayout.ready()
                    })
            })
    }

    navigateToSesion(sesion_id) {
        if (!this.sesionInstancias[sesion_id]) {
            var pesaiN = new PlanEstudioSesionAplicadaInstancia()
            pesaiN.plan_estudio_sesion_id = sesion_id
            pesaiN.usuario_id = this.authService.getUserData().id
            pesaiN.estado = 1
            pesaiN.avance = 0
            pesaiN.plan_estudio_aplicado_instancia_id = this.planEstudioAplicado.plan_estudio_aplicado_instancias[0].id
            this.pesaService.save(pesaiN).then(response => {
                this.router.navigate([this.sesiones_route, sesion_id])
            })
        } else {
            this.router.navigate([this.sesiones_route, sesion_id])
        }
    }

    goBack() {
        this.location.back()
    }

    pendiente(pesai: PlanEstudioSesionAplicadaInstancia) {
        if (pesai) return pesai.avance >= 0
        return false
    }

    completada(pesai: PlanEstudioSesionAplicadaInstancia) {
        if (pesai) return pesai.avance == 1
        return false
    }

    animationSlideInDone() {}
}
