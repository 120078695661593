import { Component, Input, HostBinding, OnInit } from "@angular/core"
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"

@Component({
    selector: "estrellas-rating",
    templateUrl: "estrellas_rating.component.html",
    styleUrls: ["estrellas_rating.component.scss"],
    animations: [
        trigger("slideIn", [
            transition("* => *", [
                query(".slideIn", style({ opacity: 0 }), { optional: true }),
                query(
                    ".slideIn",
                    [
                        stagger(200, [
                            animate(
                                "0.2s ease-in",
                                keyframes([
                                    style({ opacity: 0.8, transform: "translateY(-10px)", offset: 0 }),
                                    style({ opacity: 1, transform: "translateY(3px)", offset: 0.8 }),
                                    style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
                                ])
                            )
                        ])
                    ],
                    { optional: true }
                )
            ])
        ])
    ]
})
export class EstrellasRatingComponent implements OnInit {
    _starNumber: number
    stars: any[] = [
        { name: "star-o", active: false },
        { name: "star-o", active: false },
        { name: "star-o", active: false }
    ]
    enableStars: boolean = false
    @HostBinding("class.with-height") hasStars: boolean = false
    @Input() autoStart: boolean = false

    @Input()
    set starNumber(starNumber: any) {
        this._starNumber = starNumber
        this.hasStars = this.starNumber != null && this.starNumber != undefined
        this.setStars()
    }
    get starNumber() {
        return this._starNumber
    }

    constructor() {}

    ngOnInit() {
        if (this.autoStart) {
            this.startAnimation()
        }
    }

    setStars() {
        if (this.hasStars) {
            this.stars[0].name = this.starNumber > 0 ? "star-half-o" : this.stars[0].name
            this.stars[0].name = this.starNumber >= 1 ? "star" : this.stars[0].name
            this.stars[0].active = this.starNumber > 0
            this.stars[1].name = this.starNumber > 1 ? "star-half-o" : this.stars[1].name
            this.stars[1].name = this.starNumber >= 2 ? "star" : this.stars[1].name
            this.stars[1].active = this.starNumber > 1
            this.stars[2].name = this.starNumber > 2 ? "star-half-o" : this.stars[2].name
            this.stars[2].name = this.starNumber >= 3 ? "star" : this.stars[2].name
            this.stars[2].active = this.starNumber > 2
        }
    }

    startAnimation() {
        if (this.hasStars) {
            this.enableStars = true
        }
    }
}
