import { PuntajePlanPersonalesModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UtilModule, TranslationsModule } from "@puntaje/shared/core"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { routing } from "./alumnos_plan_personales.routing"

import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { HomePlanesPersonalesComponent } from "./home-planes-personales/home-planes-personales.component"

import { NebuModule } from "@puntaje/nebulosa/api-services"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AlumnosLayoutsModule,
        PlanPersonalesModule,
        routing,
        EnsayosModule,
        SharedModule,
        NebuModule,
        TranslationsModule,
        PlanClasesModule,
        CUILayoutsModule,
        PuntajePlanPersonalesModule
    ],
    exports: [],
    declarations: [HomePlanesPersonalesComponent],
    providers: []
})
export class AlumnosPlanPersonalesModule {}
