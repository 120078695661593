import { BaseModel, Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { UsuarioChile } from "./usuario_chile.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"
import { PreferenciaCarreraSede } from "@puntaje/carreras/api-services"

export class UsuarioRegistroUst extends UsuarioRegistroBase {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public rut: string
    public pasaporte: string
    public telefono: string
    public celular: string
    public email: string
    public lugar_id: number
    public password: string
    public password_confirmation: string
    public establecimiento_id: number
    public nivel_id: number
    public nem: number
    public year_psu: number

    @SaveType(() => Establecimiento) public colegio: Establecimiento

    public preferenciaCarreraSedes: PreferenciaCarreraSede[] = []

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    constructor() {
        super()
        this.colegio = new Establecimiento()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno + " " + this.apellido_materno
    }

    public toString() {
        return this.email
    }

    public setPreferenciaCarrera(preferencias) {
        this.preferenciaCarreraSedes = []
        for (let preferencia of preferencias) {
            let p = new PreferenciaCarreraSede()
            p.carreraSedeId = preferencia.id
            p.orden = preferencia.orden
            this.preferenciaCarreraSedes.push(p)
        }
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.email = this.email
        user.password = this.password
        user.password_confirmation = this.password_confirmation
        user.pasaporte = this.pasaporte

        if (this.colegio) {
            user.establecimiento_id = this.colegio.id
        }
        if (this.nivel_id) {
            user.nivel_id = this.nivel_id
        }
        user.pais_id = 1 // Generalizar
        user.rol_id = 3 // Generalizar, debería siempre ser 3 (alumno)
        user.usuario_colombia = null
        if (!user.usuario_chile) {
            user.usuario_chile = new UsuarioChile()
        }
        user.usuario_chile.rut = this.rut
        user.usuario_chile.telefono = this.telefono
        user.usuario_chile.celular = this.celular
        user.usuario_chile.nem = this.nem
        user.usuario_chile.year_psu = this.year_psu
        user.preferencia_carrera_sedes = this.preferenciaCarreraSedes

        // para traer carrera token
        user.with_carreras_token = 1

        return user
    }
}
