<ll-titulo>Sesión</ll-titulo>

<plan-estudio-sesion
    *ngIf="plan_estudio_id && sesion_id && sesiones_route"
    [plan_estudio_aplicado_id]="plan_estudio_id"
    [plan_estudio_sesion_id]="sesion_id"
    [sesiones_route]="sesiones_route"
    [planes_route]="planesEstudioRoute"
    [enableDudas]="enableDudas"
></plan-estudio-sesion>
