import { Component, Input, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Directive } from "@angular/core"
import { Router } from "@angular/router"
import { PreguntasEnsayoComponent } from "@puntaje/puntaje/new-modules/ensayos"
import {
    Evaluacion,
    EvaluacionInstancias,
    EvaluacionInstancia,
    PlanEstudioContenidoInstancia,
    PlanEstudioContenidoInstancias
} from "@puntaje/puntaje/api-services"
import { AuthService, ScrollToService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { trigger, state, style, animate, transition, keyframes, query, stagger } from "@angular/animations"
import { RealizarEnsayoService } from "@puntaje/puntaje/new-modules/ensayos"
import { Subscription } from "rxjs"

@Component({
    selector: "plan-estudios-evaluacion",
    templateUrl: "plan_estudios_evaluacion.component.html",
    styleUrls: ["plan_estudios_evaluacion.component.scss"],
    animations: [
        trigger("fadeIn", [
            state("in", style({ opacity: 1 })),
            transition("void => *", [
                animate(
                    "0.8s 0ms ease-in",
                    keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1.0 })])
                )
            ])
        ])
    ]
})
export class PlanEstudiosEvaluacionComponent implements OnInit {
    @ViewChild("preguntasEnsayo") preguntasEnsayo: PreguntasEnsayoComponent
    evaluacion: Evaluacion
    @Input() evaluacionId: number
    @Input() planEstudioContenidoInstancia: PlanEstudioContenidoInstancia
    @Input() enableDudas: boolean = true
    @Input() enableReporte: boolean = true
    evaluacionInstanciaId: number
    previousEvaluacionInstancia: EvaluacionInstancia
    evaluacionInstancia: EvaluacionInstancia
    //Valores de este enum están definidos abajo en este mismo archivo
    evaluationState: EvaluationStates = EvaluationStates.Start
    evaluationStates = EvaluationStates
    loadClock: boolean = false
    evaluacionPorcentaje: number = 0
    evaluacionNota: number = 0
    evaluacionPorcentajeAnimated: number = 0
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    showLoader: boolean = false
    animState: string = "in"
    graduate: boolean = config.plataforma.name == "Graduate" ? true : false

    readyEvaluacionSub: Subscription
    readySub: Subscription
    evaluacionEntregadaSub: Subscription

    constructor(
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected scrollToService: ScrollToService,
        protected authService: AuthService,
        protected realizarEnsayoService: RealizarEnsayoService,
        private peciService: PlanEstudioContenidoInstancias
    ) {}

    ngOnInit() {
        //revisar si existe una evaluación previa
        this.evaluacionInstanciasService
            .where({
                evaluacion_instancia: {
                    evaluacion_id: this.evaluacionId,
                    usuario_id: this.authService.getUserData().id
                }
            })
            .then((eis: EvaluacionInstancia[]) => {
                if (eis.length > 0) {
                    //si la hay, mostrarla
                    this.previousEvaluacionInstancia = eis[eis.length - 1]
                    this.evaluacionPorcentaje = Math.ceil(
                        (this.previousEvaluacionInstancia.correctas * 100) /
                            (this.previousEvaluacionInstancia.correctas +
                                this.previousEvaluacionInstancia.omitidas +
                                this.previousEvaluacionInstancia.incorrectas)
                    )

                    this.evaluacionNota = this.getNotaEscalaSiete(
                        this.previousEvaluacionInstancia.correctas,
                        this.previousEvaluacionInstancia.incorrectas,
                        this.previousEvaluacionInstancia.omitidas,
                        null
                    )
                    setTimeout(() => {
                        this.evaluacionPorcentajeAnimated = this.evaluacionPorcentaje
                    }, 500)
                } else {
                    //si no la hay, comenzar la evaluación
                    this.evaluationState = EvaluationStates.Evaluating
                }
            })

        this.readyEvaluacionSub = this.realizarEnsayoService.readyEvaluacion.subscribe(
            this.onReadyEvaluacion.bind(this)
        )
        this.evaluacionEntregadaSub = this.realizarEnsayoService.evaluacionEntregada.subscribe(
            this.onEvaluationSent.bind(this)
        )
    }

    onReadyEvaluacion(evaluacion) {
        this.evaluacion = evaluacion
    }

    onSendEvaluation() {
        this.showLoader = true
        this.preguntasEnsayo.entregarEnsayo(true)
        this.evaluacionPorcentajeAnimated = 0
        this.scrollToService.scrollToTop()
    }

    onRepeatEvaluation() {
        this.evaluationState = EvaluationStates.Evaluating
    }

    onEvaluationSent(evaluacionInstanciaId: number) {
        this.evaluacionInstanciaId = evaluacionInstanciaId
        this.evaluationState = EvaluationStates.Results
        this.evaluacionInstanciasService.find(this.evaluacionInstanciaId).then((ei: EvaluacionInstancia) => {
            if (this.planEstudioContenidoInstancia && this.planEstudioContenidoInstancia.avance != 1) {
                this.planEstudioContenidoInstancia.avance = 1

                let calificacion = Math.ceil(ei.calificacion)
                let tipoInstrumento = ei.evaluacion.evaluacion_tipo.evaluacion_tipo
                let pos = config.evaluaciones[tipoInstrumento].estrellas.findIndex(
                    (intervalo: any) => calificacion >= intervalo.min && calificacion <= intervalo.max
                )
                let estrellas = pos >= 0 ? 0.5 + pos * 0.5 : 0.0

                this.planEstudioContenidoInstancia.estrellas = estrellas
                this.peciService
                    .update(this.planEstudioContenidoInstancia.id, this.planEstudioContenidoInstancia)
                    .then((i: PlanEstudioContenidoInstancia) => {
                        if (i) this.planEstudioContenidoInstancia = i
                        this.showLoader = false
                    })
            } else {
                this.showLoader = false
            }
        })
    }

    onEvaluationInstanciaReady(evaluacionInstancia: EvaluacionInstancia) {
        this.evaluacionInstancia = evaluacionInstancia
        this.evaluacionPorcentaje = Math.ceil(
            (this.evaluacionInstancia.correctas * 100) /
                (this.evaluacionInstancia.correctas +
                    this.evaluacionInstancia.omitidas +
                    this.evaluacionInstancia.incorrectas)
        )
        setTimeout(() => {
            this.evaluacionPorcentajeAnimated = this.evaluacionPorcentaje
        }, 1000)
    }

    ngOnDestroy() {
        this.readyEvaluacionSub.unsubscribe()
        // this.readySub.unsubscribe()
        this.evaluacionEntregadaSub.unsubscribe()
    }

    getNotaEscalaSiete(correctas: number, incorrectas: number, omitidas: number, escala: any) {
        let min, mid, max, p_mid
        mid = 4.0
        if (escala != null) {
            min = escala.min
            max = escala.max
            p_mid = escala.p_mid
        } else {
            min = 1.0
            max = 7.0
            p_mid = 0.6
        }
        const totales = correctas + incorrectas + omitidas
        const fraccion = (min * correctas) / totales
        let nota = min

        if (fraccion <= p_mid) {
            nota = min + fraccion * ((mid - min) / p_mid)
        } else {
            const fraccionAprobado = (correctas - p_mid * totales) / (totales - p_mid * totales)
            const temp = mid + fraccionAprobado * ((max - mid) / min)
            nota = temp > max ? max : temp
        }

        return nota
    }
}

@Directive({
    selector: "plan-estudio-evaluacion-progress-buttons-slot-up,plan-estudio-evaluacion-progress-buttons-slot-down"
})
export class PlanEstudiosEvaluacionComponentTags {}
enum EvaluationStates {
    Start,
    Evaluating,
    Results
}
