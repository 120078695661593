<loading-layout #loadingLayout>
    <plan-estudio-navbar
        [planEstudioAplicado]="plan_estudio_aplicado"
        [enableNext]="enableProgressBtn"
        (onGoToNext)="goToNext()"
        [planes_route]="planes_route"
    ></plan-estudio-navbar>
    <div class="clearfix" *ngIf="plan_estudio_componentes">
        <div class="menu-sesion col-menu">
            <plan-estudio-sesion-menu
                [planEstudioComponentes]="plan_estudio_componentes"
                [contenidoInstancias]="contenidoInstancias"
                [currentComponentIndex]="activeComponent"
                (onComponentChange)="onMenuChangeComponent($event)"
                *ngIf="plan_estudio_componentes"
            ></plan-estudio-sesion-menu>
        </div>
        <div class="col-content content-wrap">
            <div *ngFor="let component of plan_estudio_componentes; let i = index" class="enforce-title">
                <div *ngIf="i == activeComponent">
                    <div *ngIf="type(component) === mType">
                        <material
                            [material]="component.material"
                            [showVolver]="false"
                            [enableReporte]="enableReporte"
                        ></material>
                        <div class="progress-btns clearfix" *ngIf="enableProgressBtn">
                            <ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="type(component) === lType">
                        <h3 class="component-title">Evaluación</h3>
                        <plan-estudios-evaluacion
                            [evaluacionId]="hash_evaluaciones[plan_estudio_sesion.id][component.orden]"
                            [planEstudioContenidoInstancia]="contenidoInstancias[component.id + component.tipo]"
                            *ngIf="checkIfEvaluationExists(component.orden)"
                            [enableDudas]="false"
                            [enableReporte]="enableReporte"
                        >
                            <plan-estudio-evaluacion-progress-buttons-slot-up>
                                <ng-container *ngTemplateOutlet="progressButtonsUp"></ng-container>
                            </plan-estudio-evaluacion-progress-buttons-slot-up>
                            <plan-estudio-evaluacion-progress-buttons-slot-down>
                                <ng-container *ngTemplateOutlet="progressButtonsDown"></ng-container>
                            </plan-estudio-evaluacion-progress-buttons-slot-down>
                        </plan-estudios-evaluacion>
                        <div *ngIf="!checkIfEvaluationExists(component.orden)">
                            <ng-container *ngTemplateOutlet="notYet"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="plan_estudio_componentes.length == 0">
                <ng-container *ngTemplateOutlet="notYet"></ng-container>
            </div>
        </div>
    </div>
</loading-layout>

<ng-template #progressButtonsDown>
    <!--Botones de progreso-->
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToNextComponent()"
        *ngIf="activeComponent < plan_estudio_componentes.length - 1"
    >
        Siguiente
        <fa name="arrow-right" class="icono-right"></fa>
    </button>
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToNextSesion()"
        *ngIf="nextSession && activeComponent == plan_estudio_componentes.length - 1"
    >
        Siguiente Sesión
        <fa name="arrow-right" class="icono-right"></fa>
    </button>
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToEnd()"
        *ngIf="!nextSession && activeComponent == plan_estudio_componentes.length - 1"
    >
        Volver a Planes de Estudio
        <fa name="arrow-right" class="icono-right"></fa>
    </button>
</ng-template>
<ng-template #progressButtonsUp>
    <!--Botones de progreso-->
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToNextComponent()"
        *ngIf="activeComponent < plan_estudio_componentes.length - 1"
    >
        Siguiente
        <fa name="arrow-right" class="icono-right"></fa>
    </button>
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToNextSesion()"
        *ngIf="nextSession && activeComponent == plan_estudio_componentes.length - 1"
    >
        Siguiente Sesión
        <fa name="arrow-right" class="icono-right"></fa>
    </button>
    <button
        type="button"
        class="btn btn-default btn-progress pull-right"
        (click)="goToEnd()"
        *ngIf="!nextSession && activeComponent == plan_estudio_componentes.length - 1"
    >
        Volver a Planes de Estudio
        <fa name="arrow-right" class="icono-right"></fa>
    </button>
</ng-template>

<ng-template #notYet>
    <div class="contenido-placeholder">
        <ogr-icon name="clock" class="placeholder-icon"></ogr-icon>
        <div class="inner-text">
            <h2>Pronto</h2>
            <p>Esta sección aún no se encuentra disponible.</p>
        </div>
    </div>
</ng-template>
