import {
    PlanClasesDeAlumnosComponent,
    PlanClaseAlumnoComponent,
    ClasePlanClaseComponent,
    PlanesEstudiosComponent,
    PlanEstudioSesionesComponent,
    PlanEstudioSesionComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { AuthGuard } from "@puntaje/shared/core"
import { AsignaturasGuard } from "@puntaje/nebulosa/api-services"
import { TokenGuard } from "@puntaje/puntaje/services"
import { HomePlanesEstudiosComponent } from "./home_planes_estudios.component"

export const routes: Routes = [
    /* Plan estudio antiguo: borrar cuando se deje de ocupar */
    {
        path: "planes_estudios",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanesEstudiosComponent,
                canActivate: [AuthGuard, AsignaturasGuard],
                data: { planesEstudioRoute: "/planes_estudios" }
            }
        ]
    },
    {
        path: "planes_estudios/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanEstudioSesionesComponent,
                canActivate: [AuthGuard, AsignaturasGuard],
                data: { planesEstudioRoute: "/planes_estudios" }
            }
        ]
    },
    {
        path: "planes_estudios/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanEstudioSesionComponent,
                canActivate: [AuthGuard, AsignaturasGuard],
                data: { planesEstudioRoute: "/planes_estudios" }
            }
        ]
    },
    /* --------------------------------------------------------- */
    {
        path: "planes_estudio",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [AuthGuard, AsignaturasGuard],
                data: { showPlanesCompartidosProfesor: false }
            }
        ]
    },
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { showPlanesCompartidosProfesor: false }
            }
        ]
    },
    {
        path: "asignatura/:asignatura_id/planes_estudio",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [AuthGuard, AsignaturasGuard],
                data: { showPlanesCompartidosProfesor: false }
            }
        ]
    },
    {
        path: "asignatura/:asignatura_id/plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { showPlanesCompartidosProfesor: false }
            }
        ]
    },
    {
        path: "plan_estudios/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard, AsignaturasGuard],
                data: { planUrl: "/plan_estudios" }
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { planUrl: "/plan_estudios", enableDudas: false }
            }
        ]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseAlumnoComponent, canActivate: [AuthGuard, AsignaturasGuard] }]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planes_clases",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePlanesEstudiosComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
