import { Component, ViewChild, OnInit, Input } from "@angular/core"
import {
    PlanEstudioAplicadoInstancia,
    PlanEstudioAplicado,
    PlanEstudioSesionAplicadaInstancia,
    PlanEstudioSesionAplicadaInstancias,
    PlanEstudioAplicadoInstancias
} from "@puntaje/puntaje/api-services"
import { PlanEstudioSesion, PlanEstudio } from "@puntaje/nebulosa/api-services"
import { EstrellasRatingComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

@Component({
    selector: "plan-estudio-sesion-box",
    templateUrl: "plan_estudio_sesion_box.component.html",
    styleUrls: ["plan_estudio_sesion_box.component.scss"]
})
export class PlanEstudioSesionBoxComponent implements OnInit {
    _planEstudioSesion: any
    isDone: boolean = false
    progressBarValue: number = 0
    animatedProgressBarValue: number = 0

    @Input() planEstudio: PlanEstudio
    @Input() planEstudioSesionAplicadaInstancia: PlanEstudioSesionAplicadaInstancia
    @Input() planEstudioAplicado: PlanEstudioAplicado
    @Input() pointRight: boolean = false
    @Input() sesiones_route: string
    @ViewChild("starRating") starRating: EstrellasRatingComponent
    @Input() previewMode: boolean = false

    @Input()
    set planEstudioSesion(planEstudioSesion: any) {
        this._planEstudioSesion = planEstudioSesion
    }
    get planEstudioSesion() {
        return this._planEstudioSesion
    }

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected peaiService: PlanEstudioAplicadoInstancias,
        protected pesaService: PlanEstudioSesionAplicadaInstancias
    ) {}

    ngOnInit() {
        this.checkIsDone()
        this.checkProgress()
        setTimeout(() => {
            this.animatedProgressBarValue = this.progressBarValue
            if (this.starRating) {
                this.starRating.startAnimation()
            }
        }, 600)
    }

    navigateToSesion() {
        if (!this.planEstudioSesionAplicadaInstancia) {
            var pesaiN = new PlanEstudioSesionAplicadaInstancia()
            pesaiN.plan_estudio_sesion_id = this.planEstudioSesion.id
            pesaiN.usuario_id = this.authService.getUserData().id
            pesaiN.estado = 1
            pesaiN.avance = 0
            pesaiN.plan_estudio_aplicado_instancia_id = this.planEstudioAplicado.plan_estudio_aplicado_instancias[0].id
            this.pesaService.save(pesaiN).then(response => {
                this.router.navigate([this.sesiones_route, this.planEstudioSesion.id])
            })
        } else {
            this.router.navigate([this.sesiones_route, this.planEstudioSesion.id])
        }
    }

    checkIsDone() {
        if (this.planEstudioSesionAplicadaInstancia) {
            this.isDone = this.planEstudioSesion.cantidad_contenidos === this.planEstudioSesionAplicadaInstancia.avance
        }
    }

    checkProgress() {
        if (this.planEstudioSesionAplicadaInstancia) {
            this.progressBarValue =
                (this.planEstudioSesionAplicadaInstancia.avance / this.planEstudioSesion.cantidad_contenidos) * 100
        }
    }

    navigateToPreviewSesion() {
        this.router.navigate([this.sesiones_route, this.planEstudioSesion.id])
    }
}
