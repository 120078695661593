import { Component, Input, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef } from "@angular/core"
import { Router } from "@angular/router"
import {
    PlanEstudioAplicado,
    PlanEstudioAplicados,
    PlanEstudioAplicadoInstancia,
    PlanEstudioAplicadoInstancias
} from "@puntaje/puntaje/api-services"
import { PlanEstudio, PlanEstudios } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { trigger, state, style, animate, transition, keyframes } from "@angular/animations"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "planes-estudios-alumno",
    templateUrl: "planes_estudios_alumno.component.html",
    styleUrls: ["planes_estudios_alumno.component.scss"],

    animations: [
        trigger("fadeIn", [
            state("initial", style({ opacity: 0 })),
            state("in", style({ opacity: 1 })),
            transition("initial => in", [
                animate(
                    "0.5s 500ms ease-in",
                    keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1.0 })])
                )
            ])
        ])
    ]
})
export class PlanesEstudiosAlumnoComponent implements OnInit, AfterViewInit {
    planesEstudioAplicados: PlanEstudioAplicado[]
    instancias: { [x: number]: PlanEstudioAplicadoInstancia } = {}
    addBtnState: string = "initial"
    @Input() planes_route: string = "/plan_estudios"
    @Output() instanciasChanged: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    hideProgress: boolean = false
    @ViewChild("misPlanes", { static: true }) misPlanes: ElementRef
    colNumber1: boolean = false
    colNumber2: boolean = false
    colNumber3: boolean = false
    colNumber4: boolean = false

    constructor(
        private peaService: PlanEstudioAplicados,
        private peaiService: PlanEstudioAplicadoInstancias,
        private authService: AuthService,
        private planEstudioService: PlanEstudios,
        private router: Router
    ) {}

    ngOnInit() {
        this.loadInstancias()
    }

    ngAfterViewInit() {
        this.checkSize()
    }

    loadInstancias() {
        this.loadingLayout.standby()
        this.peaService
            .where({
                include: "plan_estudio_aplicado_instancias",
                methods: "[inscritos]",
                with_estrellas: 1,
                plan_estudio_aplicado_instancia: { usuario_id: this.authService.getUserData().id, estado: 1 }
            })
            .then((planes: PlanEstudioAplicado[]) => {
                this.planesEstudioAplicados = planes
                planes.forEach((i, index) => {
                    i.plan_estudio_aplicado_instancias.forEach((j, index2) => {
                        this.instancias[i.id] = j
                    })
                })
                this.addBtnState = "in"
                this.loadingLayout.ready()
            })
    }

    handleDisabled(value: boolean) {
        this.loadInstancias()
        this.instanciasChanged.emit(true)
    }

    // Hasta 4 columnas según tamaño
    checkSize() {
        let width = this.misPlanes.nativeElement.offsetWidth
        if (width > 1100) {
            this.colNumber1 = false
            this.colNumber2 = false
            this.colNumber3 = false
            this.colNumber4 = true
        } else if (width > 700 && width <= 1100) {
            this.colNumber1 = false
            this.colNumber2 = false
            this.colNumber3 = true
        } else if (width > 500 && width <= 700) {
            this.colNumber1 = false
            this.colNumber2 = true
        } else {
            this.colNumber1 = true
        }
    }
}
