import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"
import { GrupoUsuarios } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "home-prueba-transicion",
    templateUrl: "./home-prueba-transicion.component.html",
    styleUrls: ["./home-prueba-transicion.component.scss"]
})
export class HomePruebaTransicionComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []
    disableMenuItem: boolean = false
    config: typeof config = config

    constructor(
        protected store: Store<State>,
        protected grupoUsuariosService: GrupoUsuarios,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos_paes",
                params: { tipo_instrumento: "prueba de transición" },
                label: "Realizar Ensayo",
                text: "Practica realizando ensayos similares a la PAES.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/ensayos/historial/",
                params: { tipo_instrumento: "prueba de transición" },
                label: "Historial de Ensayos",
                text: "Encuentra aquí la lista de todas las PAES que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            }
        ]
    }
}
