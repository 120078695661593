import { Component, Input, EventEmitter, Output } from "@angular/core"
import { Router } from "@angular/router"
import {
    PlanEstudioAplicado,
    PlanEstudioAplicados,
    PlanEstudioAplicadoInstancia,
    PlanEstudioAplicadoInstancias
} from "@puntaje/puntaje/api-services"
import { PlanEstudio, PlanEstudios, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"

@Component({
    selector: "planes-estudios-asignatura",
    templateUrl: "planes_estudios_asignatura.component.html",
    styleUrls: ["planes_estudios_asignatura.component.scss"]
})
export class PlanesEstudiosAsignaturaComponent {
    planesEstudioAplicados: PlanEstudioAplicado[]
    instancias: { [x: number]: PlanEstudioAplicadoInstancia } = {}
    planesEstudio: PlanEstudio[]
    @Input() planes_route: string
    //Este es un objeto custom de asignatura, no confundir con Asignatura de nebulosa
    _asignatura: any
    @Input() previewMode: boolean = false
    @Output() noPlanes = new EventEmitter<any>()

    constructor(
        protected peaService: PlanEstudioAplicados,
        protected peaiService: PlanEstudioAplicadoInstancias,
        protected authService: AuthService,
        protected planEstudioService: PlanEstudios,
        protected asignaturasService: Asignaturas,
        protected router: Router
    ) {}

    @Input()
    set asignatura(asignatura: any) {
        const oldAsignatura = this._asignatura
        this._asignatura = asignatura
        if (this._asignatura && oldAsignatura != asignatura) {
            this.getPlanEstudios()
        }
    }
    get asignatura() {
        return this._asignatura
    }

    async getPlanEstudios() {
        let params: any = {
            plan_estudio_aplicado: { asignatura_id: this.asignatura.id, estado: 1 },
            plan_estudio_aplicado_usuario: {
                receptor_type: "Usuario",
                receptor_id: [this.authService.getUserData().id, null]
            },
            methods: "[inscritos]"
        }

        this.peaService.where(params).then((planes: PlanEstudioAplicado[]) => {
            params.include = "plan_estudio_aplicado_instancias:plan_estudio_sesion_aplicada_instancias"
            params.plan_estudio_aplicado_instancia = { usuario_id: this.authService.getUserData().id, estado: 1 }
            delete params.plan_estudio_aplicado_usuario
            delete params.plan_estudio_aplicado.estado
            this.planesEstudioAplicados = planes

            if (planes.length == 0) {
                this.noPlanes.emit(this.asignatura)
            }

            this.peaService.where(params).then((planesWithInstancias: PlanEstudioAplicado[]) => {
                planesWithInstancias.forEach((i, index) => {
                    i.plan_estudio_aplicado_instancias.forEach((j, index2) => {
                        this.instancias[i.id] = j
                    })
                })
            })
        })
    }

    navigateToPlan(plan_id) {
        if (!this.instancias[plan_id]) {
            this.peaiService
                .where({
                    plan_estudio_aplicado_instancia: {
                        plan_estudio_aplicado_id: plan_id,
                        usuario_id: this.authService.getUserData().id,
                        estado: 0
                    }
                })
                .then((peais: PlanEstudioAplicadoInstancia[]) => {
                    if (peais.length > 0) {
                        let peaiN = peais[0]
                        peaiN.estado = 1
                        this.peaiService.update(peaiN.id, peaiN).then((peai: PlanEstudioAplicadoInstancia) => {
                            this.router.navigate([this.planes_route, plan_id])
                        })
                    } else {
                        let peaiN = new PlanEstudioAplicadoInstancia()
                        peaiN.plan_estudio_aplicado_id = plan_id
                        peaiN.usuario_id = this.authService.getUserData().id
                        peaiN.estado = 1
                        peaiN.avance = 0
                        this.peaiService.save(peaiN).then(response => {
                            this.router.navigate([this.planes_route, plan_id])
                        })
                    }
                })
        } else {
            this.router.navigate([this.planes_route, plan_id])
        }
    }

    navigateToPreviewPlan(plan_id) {
        this.router.navigate([this.planes_route, plan_id])
    }
}
