import { PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { UtilModule } from "@puntaje/shared/core"

import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

import { NebuModule } from "@puntaje/nebulosa/api-services"

import { routing } from "./alumnos_ensayos.routing"
import { HomePruebaTransicionComponent } from "./home-prueba-transicion/home-prueba-transicion.component"
import { CUIContainersModule, CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        AlumnosLayoutsModule,
        Angular2FontawesomeModule,
        UtilModule,
        SharedModule,
        NebuModule,
        routing,
        CUIContainersModule,
        CUILayoutsModule,
        PuntajeEnsayosModule
    ],
    declarations: [HomePruebaTransicionComponent],
    exports: []
})
export class AlumnosEnsayosModule {}
