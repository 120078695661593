import { Component, ViewChild, OnInit, Input } from "@angular/core"
import { PlanEstudioAplicadoInstancia, PlanEstudioAplicado } from "@puntaje/puntaje/api-services"
import { PlanEstudio } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { EstrellasRatingComponent } from "@puntaje/puntaje/new-modules/shared"

@Component({
    selector: "datos-generales-plan-estudio",
    templateUrl: "datos_generales_plan_estudio.component.html",
    styleUrls: ["datos_generales_plan_estudio.component.scss"]
})
export class DatosGeneralesPlanEstudioComponent implements OnInit {
    /*
    La idea es que en diferentes lados este componente puede recibir distintos parámetros (a veces un plan de estudio,
    a veces un plan de estudio aplicado). Se pueden setear los parámetros directamente o darle un plan de estudio o
    un plan de estudio aplicado y el componente se encarga de poner los datos.
    */
    @Input() miniEnabled: boolean = false
    @Input() nombrePlan: string
    @Input() nombreAsignatura: string
    @Input() numeroSesiones: number
    _planEstudio: PlanEstudio
    @ViewChild("starRating") starRating: EstrellasRatingComponent
    @Input() planEstudioAplicadoInstancia: PlanEstudioAplicadoInstancia
    @Input()
    set planEstudio(planEstudio: PlanEstudio) {
        this._planEstudio = planEstudio
        if (this._planEstudio) {
            if (!this.nombrePlan) this.nombrePlan = this._planEstudio.plan_estudio
            if (this._planEstudio.plan_estudio_sesiones) {
                this.numeroSesiones = this._planEstudio.plan_estudio_sesiones.length
            }
        }
    }
    get planEstudio() {
        return this._planEstudio
    }

    @Input()
    _planEstudioAplicado: PlanEstudioAplicado
    @Input()
    set planEstudioAplicado(planEstudioAplicado: PlanEstudioAplicado) {
        this._planEstudioAplicado = planEstudioAplicado
        if (this._planEstudioAplicado) {
            this.nombrePlan =
                this._planEstudioAplicado.plan_estudio_aplicado || this._planEstudioAplicado.plan_estudio_nombre
            this.nombreAsignatura = this._planEstudioAplicado.asignatura_nombre
        }
    }
    get planEstudioAplicado() {
        return this._planEstudioAplicado
    }

    constructor(private authService: AuthService) {}

    ngOnInit() {
        if (this.starRating) {
            this.starRating.startAnimation()
        }
    }
}
