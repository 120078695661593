import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, ViewChild, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    @Input() pagina: string = "home"
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({})
    }
}
