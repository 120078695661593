<ng-template [ngIf]="isLoggedIn()">
    <div class="ingresa">
        <h3>Ingresar</h3>
        <div class="clearfix">
            <cui-button (click)="logout()" *ngIf="isLoggedIn()" class="pull-right btn_style">Cerrar sesión</cui-button>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!isLoggedIn()">
    <div class="ingresa">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <h3>Iniciar sesión</h3>
            <!--<div class="mensaje-error" *ngIf="formHasErrors()">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
                <div *ngIf="checkEmail()">
                    <p>{{ config.mensajesLogin.invalidLoginId }}</p>
                </div>
                <div *ngIf="checkPassword()"><p>Debes incluir una contraseña.</p></div>
            </div>-->
            <div class="mensaje-error" *ngIf="invalidLogin">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
            </div>
            <input
                type="text"
                name="email"
                [(ngModel)]="email"
                [placeholder]="config.mensajesLogin.idPlaceholder"
                *ngIf="!isLoggedIn()"
                class="form-control"
                formControlName="email"
            />
            <div id="mensaje-error-email" class="mensaje-error-input" *ngIf="checkEmail()">
                <p>{{ config.mensajesLogin.invalidLoginId }}</p>
            </div>
            <input
                type="password"
                name="password"
                [(ngModel)]="password"
                placeholder="Contraseña"
                *ngIf="!isLoggedIn()"
                class="form-control"
                formControlName="password"
            />
            <div id="mensaje-error-password" class="mensaje-error-input" *ngIf="checkPassword()">
                <p>Debes incluir una contraseña.</p>
            </div>
            <div class="btn-ingresa-container">
                <button type="submit" class="btn btn_base_1 btn-ingresa" *ngIf="!isLoggedIn()">Ingresar</button>
            </div>

            <ng-container *ngIf="enablePasswordRecovery">
                <p class="bottom-link">
                    ¿Olvidaste tu contraseña?
                    <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">Haz click aquí.</a>
                </p>
            </ng-container>

            <div class="content-under-login" #contentUnderLogin>
                <ng-content select="content-under-login"></ng-content>
            </div>
        </form>
    </div>
</ng-template>
<request-reset-password></request-reset-password>
