<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/paes']" role="button">
                    <ogr-icon name="prueba" align="text-bottom"></ogr-icon>
                    &nbsp;Ensayos PAES
                </a>
            </li>

            <li routerLinkActive="active-menu">
                <a [routerLink]="['/bibliotecas']" role="button">
                    <ogr-icon name="libro2-o" align="text-bottom"></ogr-icon>
                    &nbsp;Biblioteca
                </a>
            </li>

            <li routerLinkActive="active-menu">
                <a [routerLink]="['/planes_clases']" role="button">
                    <ogr-icon name="libro-resultados" align="text-bottom"></ogr-icon>
                    &nbsp;Planes de Clase PAES
                </a>
            </li>
        </ul>
    </div>
</nav>
