<div class="{{ asignatura?.clase }}" *ngIf="planEstudioAplicado">
    <div ribbon-flag *ngIf="isDone"></div>
    <div class="plan_estudio_wrapper">
        <div class="plan_estudio_row">
            <div class="plan_estudio_cell icon_cell">
                <div class="icon_container">
                    <ogr-icon class="icono" name="lenguaje-3"></ogr-icon>
                </div>
            </div>
            <div class="plan_estudio_cell">
                <h3 class="title" [class.ribbon-padding]="isDone">
                    {{ planEstudioAplicado.plan_estudio_aplicado || planEstudioAplicado.plan_estudio_nombre }}
                </h3>
                <div class="clearfix">
                    <h4 class="asignatura">{{ planEstudioAplicado.asignatura_nombre }}</h4>
                    <span class="sesiones" [class.show_sesiones]="showSesiones">
                        {{ planEstudio?.plan_estudio_sesiones.length }}&nbsp;{{ nombreSesiones }}
                    </span>
                </div>
                <p class="inscritos" *ngIf="planEstudioAplicado.inscritos">
                    <fa name="user-o" class="icono"></fa>
                    inscritos ({{ planEstudioAplicado.inscritos }})
                </p>
            </div>
        </div>
    </div>
    <ng-container *ngIf="planEstudioAplicadoInstancia">
        <estrellas-rating
            #starRating
            class="estrellas"
            [starNumber]="planEstudioAplicadoInstancia.estrellas"
        ></estrellas-rating>
    </ng-container>
    <div class="push-bottom"></div>
</div>
<div class="clearfix bottom-btns">
    <button
        *ngIf="planEstudioAplicadoInstancia"
        type="button"
        class="btn btn-default pull-left btn-remove btn-sm"
        (click)="disablePlan()"
    >
        {{ "estudiar.showcasebox.plan_estudios.desactivar" | t }}
    </button>
    <button type="button" class="btn btn-default pull-right btn-continue btn-sm" (click)="navigateToPlan()">
        {{ "estudiar.showcasebox.plan_estudios.continuar" | t }}
    </button>
</div>
<div class="progressbar" *ngIf="!hideProgress">
    <progressbar [striped]="true" [value]="progressBarValue" [type]="'success'">
        <i>{{ progressBarValue | percentage }}</i>
    </progressbar>
</div>
