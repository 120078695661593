import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "home_planes_estudios.component.html"
})
export class HomePlanesEstudiosComponent {
    config: typeof config = config
    menuItems: MenuItemBoxData[] = []

    constructor(protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        this.menuItems = [
            {
                route: "/plan_clases",
                params: {},
                label: "Planes de Clase",
                text: "Estudia con los Planes de Clase que te proponemos.",
                linkText: "Ir a Planes de Clase",
                icon: "plan-estudio"
            }
            // {
            //     route: "/planes_personales",
            //     params: {},
            //     label: "Planes Adaptativos",
            //     text: "Estudia con los Planes de Clase que te proponemos.",
            //     linkText: "Ir a Planes personales",
            //     icon: "plan-estudio"
            // }
        ]
    }
}
