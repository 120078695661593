import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    Injector,
    ChangeDetectorRef,
    ElementRef
} from "@angular/core"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import {
    Usuario,
    Usuarios,
    UsuarioRegistroUst,
    UsuarioRegistroUstForm,
    Establecimiento,
    Establecimientos,
    Lugar
} from "@puntaje/puntaje/api-services"
import { EmbedPreferenciaCarreraSedesBySedeComponent } from "../preferencia_carrera_sedes/embed_preferencia_carrera_sedes_by_sede.component"
import { BaseRegisterComponent } from "./base-register/base-register.component"
import { AuthService } from "@puntaje/shared/core"
import { PreferenciaCarreraSedes, CarrerasAuthService } from "@puntaje/carreras/api-services"
import { Angulartics2Facebook } from "angulartics2/facebook"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Angulartics2GoogleGlobalSiteTag } from "angulartics2/gst"

@Component({
    selector: "usuario-registro-ust",
    templateUrl: "register_ust.form.component.html",
    styleUrls: ["register.component.scss"]
})
export class RegisterUstComponent extends BaseRegisterComponent implements OnInit {
    usuarioRegistro: UsuarioRegistroUst = new UsuarioRegistroUst()
    usuarioRegistroForm: UsuarioRegistroUstForm
    params: any = UsuarioRegistroUstForm.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"
    saved: boolean = false
    universidades: string[] = [
        "Centro de Formación Técnica Santo Tomás",
        "Instituto Profesional Santo Tomás",
        "Universidad Santo Tomás"
    ]

    egresado: Clasificacion
    showYearPsu: boolean = false
    filteredEstablecimientos: Establecimiento[]

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    @ViewChild("preferencias") preferencias: EmbedPreferenciaCarreraSedesBySedeComponent

    constructor(
        protected usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        protected router: Router,
        protected injector: Injector,
        protected establecimientosService: Establecimientos,
        protected cdr: ChangeDetectorRef,
        protected authService: AuthService,
        protected carrerasAuthService: CarrerasAuthService,
        protected preferenciaCarreraSedesService: PreferenciaCarreraSedes,
        protected angularticsFb: Angulartics2Facebook,
        protected angularticsGst: Angulartics2GoogleGlobalSiteTag
    ) {
        super(usuariosService, router, null, establecimientosService)
    }

    ngOnInit() {
        this.form = UsuarioRegistroUstForm.getForm(this.usuarioRegistro, null, this.injector)

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "curso" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })
    }

    disabledId(tipoId) {
        if (tipoId == 1) {
            this.form.controls.rut.disable()
        } else {
            this.form.controls.pasaporte.disable()
        }
    }

    afterClear() {
        this.preferencias.reset()
    }

    updatePreferencias(preferencias) {
        this.usuarioRegistro.preferenciaCarreraSedes = preferencias
    }

    nivelChanged(nivelId) {
        this.showYearPsu = nivelId == this.egresado.id
    }

    afterSave(usuario: Usuario) {
        super.afterSave(usuario)
        this.angularticsFb.eventTrack("CompleteRegistration")

        if (
            environment.globalSiteTag &&
            environment.globalSiteTag[config.app.name] &&
            environment.globalSiteTag[config.app.name].sendTo
        ) {
            this.angularticsGst.eventTrack("conversion", {
                gstCustom: { send_to: environment.globalSiteTag[config.app.name].sendTo }
            })
        }
    }
}
