import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { ActivatedRoute, Router } from "@angular/router"
import { PlanEstudioAplicado } from "@puntaje/puntaje/api-services"
import { PlanEstudioSesion, PlanEstudioSesiones, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { filter, last, first } from "rxjs/operators"

@Component({
    selector: "plan-estudio-navbar",
    templateUrl: "plan_estudio_navbar.component.html",
    styleUrls: ["plan_estudio_navbar.component.scss"]
})
export class PlanEstudioNavbarComponent {
    @Input() nombrePlan: string
    @Input() nombreAsignatura: string
    @Input() enableNext: boolean
    @Input() enablePrevious: boolean
    @Input() activeComponent: number = 0
    @Input() activeComponentTotal: number = 0
    @Input() planes_route: string = "/plan_estudios"
    asignaturas: { [key: number]: AsignaturaWithConfig } = {}
    asignatura: AsignaturaWithConfig
    @Input() evaluacionTipo: string = config.plataforma.evaluacionDefault
    nextSesion: PlanEstudioSesion
    previousSesion: PlanEstudioSesion
    @Output() onGoToNext: EventEmitter<any> = new EventEmitter<any>()
    @Output() onGoToPrevious: EventEmitter<any> = new EventEmitter<any>()
    _planEstudioAplicado: PlanEstudioAplicado
    @Input()
    set planEstudioAplicado(planEstudioAplicado: PlanEstudioAplicado) {
        this._planEstudioAplicado = planEstudioAplicado
        if (this._planEstudioAplicado) {
            this.nombrePlan =
                this._planEstudioAplicado.plan_estudio_aplicado || this._planEstudioAplicado.plan_estudio_nombre
            this.nombreAsignatura = this._planEstudioAplicado.asignatura_nombre
            if (this.asignaturas) {
                this.asignatura = this.asignaturas[this._planEstudioAplicado.asignatura_id]
            }
        }
    }
    get planEstudioAplicado() {
        return this._planEstudioAplicado
    }
    // TODO: definir mejor la navegación de los planes de estudio para terminar esta parte
    /*_planEstudioSesion: PlanEstudioSesion;
    @Input()
    set planEstudioSesion(planEstudioSesion: PlanEstudioSesion) {
        this._planEstudioSesion = planEstudioSesion;
        if(this._planEstudioAplicado) this.setSesionNavValues();
    }
    get planEstudioSesion() {
        return this._planEstudioSesion;
    }*/

    constructor(
        private authService: AuthService,
        protected router: Router,
        protected store: Store<State>,
        protected pesService: PlanEstudioSesiones
    ) {}

    async ngOnInit() {
        let asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()

        asignaturasByEvaluacionTipo[this.evaluacionTipo].forEach(a => {
            this.asignaturas[a["id"]] = a
        })
        if (this.planEstudioAplicado) {
            this.asignatura = this.asignaturas[this.planEstudioAplicado.asignatura_id]
        }
    }

    /*setSesionNavValues() {
        var promesas = [];
        promesas.push(this.pesService.where({plan_estudio_sesion: {plan_estudio_id: this._planEstudioSesion.plan_estudio_id, orden: (this._planEstudioSesion.orden + 1)}}).then((nextPes: PlanEstudioSesion[]) => {
            if(nextPes.length>0) {
                this.nextSesion = nextPes[0];
                this.enableNext = true;
            }
        }));
        promesas.push(this.pesService.where({plan_estudio_sesion: {plan_estudio_id: this._planEstudioSesion.plan_estudio_id, orden: (this._planEstudioSesion.orden - 1)}}).then((prevPes: PlanEstudioSesion[]) => {
            if(prevPes.length>0) {
                this.previousSesion = prevPes[0];
                this.enablePrevious = true;
            }
        }));
        return Promise.all(promesas).then(() => {

        });
    }*/

    goToPrevious() {
        this.onGoToPrevious.emit()
    }

    goToNext() {
        this.onGoToNext.emit()
    }
}
