<div
    class="sesion-container"
    *ngIf="planEstudioSesion"
    [class.pointLeft]="!pointRight"
    [class.pointRight]="pointRight"
    [class.pendiente]="planEstudioSesionAplicadaInstancia && !isDone"
    [class.completada]="(planEstudioSesionAplicadaInstancia && isDone) || previewMode"
>
    <div ribbon-flag *ngIf="isDone"></div>
    <div>
        <div class="titulo-sesion">
            <div class="titulo-sesion-wrap">
                <h3>
                    <span [class.ribbon-padding]="isDone">{{ planEstudioSesion.sesion | capitalize }}</span>
                </h3>
            </div>
        </div>
        <button type="button" class="start-btn" (click)="navigateToSesion()" *ngIf="!previewMode">
            <span *ngIf="!planEstudioSesionAplicadaInstancia">Comenzar</span>
            <span *ngIf="planEstudioSesionAplicadaInstancia && !isDone">Continuar</span>
            <span *ngIf="planEstudioSesionAplicadaInstancia && isDone">Repasar</span>
        </button>
        <button type="button" class="start-btn" (click)="navigateToPreviewSesion()" *ngIf="previewMode">
            <span>Ver sesión</span>
        </button>
        <ng-container *ngIf="planEstudioSesionAplicadaInstancia">
            <estrellas-rating
                #starRating
                class="estrellas"
                [starNumber]="planEstudioSesionAplicadaInstancia['estrellas']"
            ></estrellas-rating>
        </ng-container>
    </div>

    <div class="progressbar" *ngIf="!previewMode">
        <progressbar [striped]="true" [value]="animatedProgressBarValue" [type]="'success'">
            <i>{{ animatedProgressBarValue | percentage }}</i>
        </progressbar>
    </div>
</div>
