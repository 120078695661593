import { Component, Input, ViewChild, ElementRef } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { PlanesEstudiosAsignaturasComponent } from "./planes_estudios_asignaturas.component"

@Component({
    selector: "planes-estudio",
    templateUrl: "planes_estudio.component.html",
    styleUrls: ["planes_estudio.component.scss"]
})
export class PlanesEstudioComponent {
    @Input() planes_route: string
    @Input() hideTitle: boolean = false

    @ViewChild("planesEstudioAsignaturas", { static: true })
    planesEstudioAsignaturas: PlanesEstudiosAsignaturasComponent

    constructor() {}

    handleInstanciasChanged(value: any) {
        this.planesEstudioAsignaturas.load()
    }
}
